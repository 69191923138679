import React, { useEffect } from 'react';
import { Container } from '@/components/Containers/Container';
import { HeaderBanner } from '@/components/Header/HeaderBanner';
import Link from 'next/link';
import { CoSupportLogo } from '@/assets/icons/CoSupportLogo';
import { PATH_AGENT, PATH_BI, PATH_CUSTOMER } from '@/constants/spa-routes';
import { DesktopMenu } from '@/components/Header/DesktopMenu';
import { NavigationMenu } from '@/components/ui/navigation-menu';
import { MobileMenu } from '@/components/Header/MobileMenu';
import { MobileMenuIcon } from '@/assets/icons/mobileMenuIcon';
import { DrawerComponent } from '@/components/ui/drawer';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { navigationItems } from '@/constants/navigation';

interface HeaderProps {
  pathname: string;
}

export default function Header({ pathname }: HeaderProps) {
  const hideOnMobile = useMediaQuery('(min-width: 1024px)');
  const hideBannerIfProduct =
    pathname === PATH_CUSTOMER.ROOT ||
    pathname === PATH_AGENT.ROOT ||
    pathname === PATH_BI.ROOT;

  useEffect(() => {
    if (hideBannerIfProduct && !hideOnMobile) {
      document.body.classList.remove('header-banner', 'bg-ebony');
    }
  }, []);
  return (
    <header className="header top-0 z-20 bg-ebony_opacity backdrop-blur-2xl">
      <Container padding="p-0">
        {hideBannerIfProduct ? (
          hideOnMobile && <HeaderBanner />
        ) : (
          <HeaderBanner />
        )}
        <div className="header-block flex justify-between items-start">
          <NavigationMenu className="w-full max-w-full flex-col items-end">
            <div className="w-full flex justify-between items-center">
              <Link href="/">
                <CoSupportLogo className="header-logo" />
              </Link>
              <DesktopMenu
                pathname={pathname}
                navigationItems={navigationItems}
              />
            </div>
          </NavigationMenu>
          <DrawerComponent
            darkMode
            triggerClassName="ml-5 min-[897px]:hidden"
            drawerTrigger={<MobileMenuIcon />}
            drawerContent={
              <MobileMenu
                pathname={pathname}
                navigationItems={navigationItems}
              />
            }
          />
        </div>
      </Container>
    </header>
  );
}
